import React from "react"
import Styles from "./feature.module.scss"
import { MainsectionText } from "src/components/section/main"
import SS1 from "src/images/android-screenshot/android-screenshot.001.png"
import SS2 from "src/images/android-screenshot/android-screenshot.002.png"
import SS3 from "src/images/android-screenshot/android-screenshot.005.png"

const Feature = () => (
  <div className="container">
    <FeatureTemp
      title="natadeCOCOスポットがみつかる・つながる！"
      image={SS1}
      alt="スクリーンショット画像"
      icon="fas fa-search"
      reverse={false}
    >
      <article>「natadeCOCO ナタデココ」スマホアプリは近くにあるnatadeCOCOスポットを検索でき、スポットが配信するコンテンツにWi-Fi経由で簡単にアクセスできるiOS/Androidアプリです。</article>
      <br />
      <article>natadeCOCOスポットはお店やイベント会場などに設置されており、その場所でのみ利用可能なコンテンツ(画像・動画など)がWi-Fi経由で配信されています。</article>
      <br />
      <article>このアプリ1つで、あらゆるnatadeCOCOスポットにアクセスすることができます。</article>
    </FeatureTemp>

    <Divider />

    <FeatureTemp
      title="スポットが配信しているコンテンツをチェック！"
      image={SS2}
      alt="スクリーンショット画像"
      icon="fas fa-tasks"
      reverse={true}
    >
      <article>地図やキーワードから、近くにあるnatadeCOCOスポットを検索することができます。</article>
      <br />
      <article>また、natadeCOCOスポットが配信しているコンテンツの一覧を確認でき、スポットの配信エリア内にいる場合にはコンテンツを利用することができます。</article>
    </FeatureTemp>

    <Divider />

    <FeatureTemp
      title="QRコードリーダー搭載！"
      image={SS3}
      alt="スクリーンショット画像"
      icon="fas fa-qrcode"
      reverse={false}
    >
      <article>natadeCOCOスポット専用QRコードも読めるQRコードリーダーを搭載しており、QRコード読み取りからスポット・コンテンツにアクセスすることもできます。</article>
      <br />
      <article>QRコードを検知するとオリジナルオブジェクトが出現する、楽しいARモードも搭載しています。</article>
      <br />
      <article className="is-size-7">
        ※ ARモードは対応端末でのみご利用いただけます。<br />
        ※ ARオブジェクトは今後のアップデートで追加予定です。
      </article>
    </FeatureTemp>
  </div>
);

export default Feature

// props
// - title ...見出し
// - children ...説明文
// - image ...スクリーンショット画像
// - alt ...スクリーンショット画像の説明文
// - icon ...見出し用アイコン画像
// - reverse ...スクショ画像の位置を逆にする (default: left)
const FeatureTemp = (props) => {
  const { title, image, alt, icon, reverse, children } = props;

  const reverseStyle = reverse && Styles.reverseColumns;

  return (
    <div className="hero">
      <div className="hero-body has-text-white">
        <div className="columns is-centered">
          <div className="column is-10">
            <div className={`columns ${reverseStyle}`}>
              {/* スクショ画像 for desktop */
                <div className="column is-one-third is-hidden-mobile"  style={{marginLeft: "1rem"}}>
                  <figure className="image is-1by2">
                    <img src={image} alt={alt} className={Styles.screenshotImage} />
                  </figure>
                </div>
              }

              <div className="column">
                {/* 見出し for mobile */
                  <div className="columns is-narrow is-vcentered is-mobile is-hidden-tablet">
                    <div className="column is-narrow" >
                      <span className="icon has-text-warning">
                        <i className={`fa-lg ${icon}`}></i>
                      </span>
                    </div>
                    <div className="column is-size-5 has-text-weight-bold has-text-white" style={{marginLeft: "-.5em"}}>
                      {title}
                    </div>
                  </div>
                }
                {/* 見出し for desktop~ */
                  <div className="columns is-narrow is-vcentered is-mobile is-hidden-mobile">
                    <div className="column is-1" >
                      <span className="icon is-large has-text-warning">
                        <i className={`fa-3x ${icon}`}></i>
                      </span>
                    </div>
                    <div className="column is-size-3 has-text-weight-bold has-text-white" style={{marginLeft: "1.5rem"}}>
                      {title}
                    </div>
                  </div>
                }

                <DotLine />

                {/* スクショ画像 for mobile */
                  <div className="column is-one-third is-offset-one-third is-hidden-tablet" style={{ margin: "-1rem auto 2rem", maxWidth: "300px" }}>
                    <figure className="image is-3by4" style={{ marginLeft: "1rem" }}>
                      <img src={image} alt={alt} className={Styles.screenshotImage} style={{objectFit: "cover", objectPosition: "50% 0"}} />
                    </figure>
                  </div>
                }
                {/* 説明文 */
                  <MainsectionText>
                    {children}
                  </MainsectionText>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Divider = () => <div className="is-divider" style={{opacity: ".25", margin: ".5rem 0"}} />;
const DotLine = () => (
  <div
    className="container"
    style={{
      margin: "-1rem 0 1.5rem",
      opacity: ".5",
      backgroundImage: "radial-gradient(circle farthest-side, #fff, #fff 20%, transparent 40%, transparent)",
      backgroundSize: "10px 10px",
      backgroundRepeat: "repeat-x",
      height: "10px"
    }}
  ></div>
);