import React from "react"
import Styles from "./hero.module.scss"
import Copy from "src/components/section/salescopy"
import Wrap from "src/components/wrap"
import logImage from "src/images/logo_for_mobile.svg"
import joinUs from "src/images/joinus.svg"

const TitleLogo = () => <img src={logImage} alt="natadeCOCO" style={{ maxWidth: "500px" }} />;

const JoinusImage = () => <img width="500" src={joinUs} alt="joinus" />;

const Hero = () => (
  <div className={`hero is-middle is-mobile has-text-white ${Styles.hero}`}>
    <div className="hero-body">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-three-fifths">
            <div className="columns">
              <div className="column is-1 is-hidden-mobile"></div>
              <div className={Styles.texts}>
                <Copy positionStyle="has-text-left">
                  <Wrap>
                    <span>お近くの</span><span>natadeCOCOスポットを</span><span>検索！</span><br />
                    <span>スポットが配信するコンテンツに</span><span>Wi-Fi経由で</span><span>アクセスできるアプリ</span>
                  </Wrap>
                </Copy>
                <div className="container has-text-centered" style={{ marginTop: "2.5rem" }}>
                  <TitleLogo />
                </div>
              </div>
              <div className="column is-1 is-hidden-mobile"></div>
            </div>
          </div>
          <div className="column has-text-centered">
            <JoinusImage />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Hero