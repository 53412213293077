import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Hero from "src/components/pocket/hero"
import Feature from "src/components/pocket/feature"
import { Download } from "src/components/install"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Hero />
      <Download />
      <Feature />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
