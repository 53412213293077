import React from "react"
import Styles from "./install.module.scss"
import AppstoreImg from "src/images/appstore.png"
import GoogleplayImg from "src/images/googleplay.png"

const MOBILE_APP_STORE_LINK_IOS="https://apps.apple.com/jp/app/natadecoco-%E3%83%8A%E3%82%BF%E3%83%87%E3%82%B3%E3%82%B3/id1494996904?mt=8"
const MOBILE_APP_STORE_IMAGE_IOS="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2020-03-04&kind=iossoftware&bubble=ios_apps"
const MOBILE_APP_STORE_LINK_ANDROID="https://play.google.com/store/apps/details?id=com.natade_coco.pocket&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
const MOBILE_APP_STORE_IMAGE_ANDROID="https://play.google.com/intl/us-en/badges/static/images/badges/ja_badge_web_generic.png"

const QRCODE_SIZE = "160px"
const ENV_IOS = "iOS 13.0 以降"
const ENV_ANDROID = "Android 8.0 以降"

const AppBadgeApple = () => (
  <div className={Styles.appStoreBadge}>
    <a href={MOBILE_APP_STORE_LINK_IOS} target="_blank" rel="noopener noreferrer">
      <img alt="App Store からダウンロード" src={MOBILE_APP_STORE_IMAGE_IOS} />
    </a>
  </div>
)

const AppBadgeGoogle = () => (
  <div className={Styles.androidStoreBadge}>
    <a href={MOBILE_APP_STORE_LINK_ANDROID} target="_blank" rel="noopener noreferrer">
      <img alt="Google Play で手に入れよう" src={MOBILE_APP_STORE_IMAGE_ANDROID} />
    </a>
  </div>
)


const AppInstallItem = ({ storeName, badgeComponent, env, qrcodeSrc, children }) => (
  <div className="content">
    <div>{badgeComponent}</div>
    <p className="is-size-7 has-text-weight-bold">(動作環境：{env})</p>
    <div className="box is-shadowless is-hidden-mobile" style={{ width: QRCODE_SIZE, height: QRCODE_SIZE, margin: "1.5rem auto 0", padding: ".25rem" }}>
      <figure className="image is-marginless">
        <img src={qrcodeSrc} alt={`QR code for ${storeName}`} />
      </figure>
    </div>
  </div>
)

const AppInstall = () => (
  <div className="columns has-text-centered">
    <div className="column is-half">
      <AppInstallItem
        storeName="App Store"
        badgeComponent={<AppBadgeApple />}
        env={ENV_IOS}
        qrcodeSrc={AppstoreImg}
      />
    </div>
    <div className="column">
      <AppInstallItem
        storeName="Google Play"
        badgeComponent={<AppBadgeGoogle />}
        env={ENV_ANDROID}
        qrcodeSrc={GoogleplayImg}
      />
    </div>
  </div>
)

export const Download = () => (
  <div className="columns is-mobile is-centered" style={{ margin: "1rem auto", width: "fit-content" }}>
    <div className="column has-text-centered-mobile" style={{ padding: "0" }}>
      <AppBadgeApple />
    </div>
    <div className="column has-text-centered-mobile" style={{ padding: "0", marginLeft: "-5px" }}>
      <AppBadgeGoogle />
    </div>
  </div>
)

export default AppInstall